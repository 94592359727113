//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {
    XModel: () => import("@/components/XModal"),
    mb: () => import("@/components/Selectors/MemberSelectCopy"),
  },

  data() {
    return {
      teamOptions: [],
      msgForm: {
        content: "", //消息内容
        reader: null, //成员id数组
        teamId: null,
      },

      users: [],
      rules: {
        teamId: [{ required: true, message: "请选择团队", trigger: "blur" }],
        content: [
          { required: true, message: "请输入消息内容", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.getTeams();
  },
  methods: {
    /**
     * 获取团队
     */
    getTeams() {
      this.$http
        .get("/Teams/GetAllTeams.ashx", {
          params: { searchText: null, type: 2 },
        })
        .then((resp) => {
          if (resp.res == 0) {
            this.teamOptions = resp.data.filter(
              (m) => m.Functions.indexOf("消息管理") != -1
            );
          }
        });
    },
    /**
     * 数据初始化
     */
    beforeOpen() {
      this.users = [];
      this.msgForm.reader = null;
      this.msgForm.content = "";
    },
    /**
     * 消息发送
     */
    handleConfirm() {
      this.msgForm.reader = this.users.map((m) => m.UsId);
      this.$refs.msgForm.validate((vail) => {
        if (vail) {
          this.$refs.xmodal.loadBtn(true);
          this.$http
            .post("/Information/SaveInformation.ashx", this.msgForm)
            .then((resp) => {
              if (resp.res == 0) {
                this.$message({
                  message: "发送成功",
                  type: "success",
                });
                this.$modal.hide("MsgMass");
              }
            })
            .finally(() => this.$refs.xmodal.loadBtn(false));
        } else {
          return false;
        }
      });
    },
    /**
     * 消息接收人选择
     */
    confirm(val) {
      this.users = [];
      this.users.push(...val);
    },
  },
};
